<template>
  <b-sidebar
    id="is-add-bank-active"
    :visible="isAddBank"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-bank', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter une nouvelle banque
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Bank"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="bank.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nom de la banque"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- rip -->
          <validation-provider
            #default="validationContext"
            name="rip"
            rules="required"
          >
            <b-form-group
              label="RIP"
              label-for="rip"
            >
              <b-form-input
                id="rip"
                v-model="bank.rip"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- iban -->
          <validation-provider
            #default="validationContext"
            name="iban"
          >
            <b-form-group
              label="IBAN"
              label-for="iban"
            >
              <b-form-input
                id="iban"
                v-model="bank.iban"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- swift -->
          <validation-provider
            #default="validationContext"
            name="swift"
          >
            <b-form-group
              label="SWIFT"
              label-for="swift"
            >
              <b-form-input
                id="swift"
                v-model="bank.swift"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Sauvegarder
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddBank',
    event: 'update:is-add-bank',
  },
  props: {
    isAddBank: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankBank = {
      name: '',
      rip: '',
      iban: '',
      swift: '',
    }

    const bank = ref(JSON.parse(JSON.stringify(blankBank)))
    const resetBank = () => {
      bank.value = JSON.parse(JSON.stringify(blankBank))
    }

    const onSubmit = async () => {
      await store.dispatch('bank/addBank', bank.value)
      emit('update:is-add-bank', false)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBank)

    return {
      bank,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
