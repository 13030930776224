<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Gestion des fournisseurs</span>
      </template>

      <supplier />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Gestion des chauffeurs</span>
      </template>

      <driver />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Catégories</span>
      </template>

      <category />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Bank</span>
      </template>

      <bank />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">TVA</span>
      </template>

      <vat />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Catégories de dépenses</span>
      </template>

      <expense-category />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mode de paiement</span>
      </template>

      <payment-method />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Zone</span>
      </template>

      <state />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Emploi</span>
      </template>

      <job />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Camion</span>
      </template>

      <truck />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import category from './category/Index.vue'
import bank from './bank/Index.vue'
import state from './state/Index.vue'
import job from './job/Index.vue'
import truck from './truck/Index.vue'
import vat from './vat/Index.vue'
import expenseCategory from './expenseCategory/Index.vue'
import paymentMethod from './paymentMethod/Index.vue'
import supplier from './supplier/Index.vue'
import driver from './driver/Index.vue'

export default {
  components: {
    BTabs,
    BTab,
    category,
    bank,
    vat,
    state,
    job,
    truck,
    expenseCategory,
    paymentMethod,
    supplier,
    driver,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    /* this.$http.get("/account-setting/data").then((res) => {
      this.options = res.data;
    }); */
  },
}
</script>
