<template>
  <b-sidebar
    id="is-add-driver-active"
    :visible="isAddDriver"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-driver', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter un nouveau chauffeur
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="nom"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="driver.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nom"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="cin"
            rules="required"
          >
            <b-form-group
              label="cin"
              label-for="cin"
            >
              <b-form-input
                id="cin"
                v-model="driver.cin"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Cin"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider>
            <b-form-group
              label="camion"
              label-for="camion"
            >
              <validation-provider
                #default="{ errors }"
                name="camion"
              >
                <v-select
                  v-model="driver.truck"
                  :options="trucks"
                  :clearable="false"
                  :reduce="truck => truck.id"
                  label="serial_number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Sauvegarder
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddDriver',
    event: 'update:is-add-driver',
  },
  props: {
    isAddDriver: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('truck', ['trucks']),

  },
  setup(props, { emit }) {
    const blankDriver = {
      name: '',
      cin: '',
      truck: '',
    }

    const driver = ref(JSON.parse(JSON.stringify(blankDriver)))
    const resetDriver = () => {
      driver.value = JSON.parse(JSON.stringify(blankDriver))
    }

    const onSubmit = async () => {
      await store.dispatch('driver/addDriver', driver.value)
      emit('update:is-add-driver', false)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDriver)

    return {
      driver,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
