<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <AddCategory
        :is-add-category.sync="isAddCategory"
      />
      <EditCategory
        :is-edit-category.sync="isEditCategory"
        :current-category="currentCategory"
      />
      <BulkUpdateProductsRelated
        :is-bulk-update-products-related.sync="isBulkUpdateProductsRelated"
        :current-category="currentCategory"
        :suppliers="suppliers"
      />
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">chercher</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="chercher"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          variant="gradient-primary"
          class="ml-1 d-inline-block"
          @click="isAddCategory = true"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter une catégorie</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="categories"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click.prevent="editCategory(props.row)"
            />
            <b-tooltip
              title="Mise à jour"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />
            <feather-icon
              :id="`invoice-row-${props.row}-setting-icon`"
              icon="SettingsIcon"
              class="cursor-pointer"
              size="16"
              @click="lunchBulkUpdateProductsRelated(props.row)"
            />
            <b-tooltip
              title="Mise a jour du prix"
              class="cursor-pointer"
              :target="`invoice-row-${props.row}-trash-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{ props.total }} résultats retrouvés</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, reactive } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index'
import AddCategory from './AddCategory.vue'
import EditCategory from './EditCategory.vue'
import BulkUpdateProductsRelated from './BulkUpdateProductsRelated.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    AddCategory,
    EditCategory,
    BulkUpdateProductsRelated,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Categorie',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Categorie',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters('category', ['categories']),
    ...mapGetters('supplier', ['suppliers']),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getCategories()
    this.getSuppliers()
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    ...mapActions('supplier', ['getSuppliers']),
    async deleteCategory(payload) {
      const result = await this.$swal({
        title: 'Êtes-vous sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, je veux supprimer cette catégorie',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      this.load = 'true'
      if (result.value) {
        await store.dispatch('category/deleteCategory', payload)
        this.load = false
      } else if (result.dismiss === 'cancel') {
        this.load = 'false'
        this.$swal({
          title: 'Fermer',
          text: 'catégorie Non supprimé',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const isAddCategory = ref(false)
    const isEditCategory = ref(false)
    const isBulkUpdateProductsRelated = ref(false)

    const currentCategory = reactive({
      id: null,
      name: '',
      reference: '',
    })

    const editCategory = payload => {
      currentCategory.id = payload.id
      currentCategory.name = payload.name
      currentCategory.reference = payload.reference
      isEditCategory.value = true
    }

    const lunchBulkUpdateProductsRelated = payload => {
      console.log(payload)
      currentCategory.id = payload.id
      currentCategory.name = payload.name
      currentCategory.reference = payload.reference
      isBulkUpdateProductsRelated.value = true
    }

    return {
      isAddCategory,
      isEditCategory,
      editCategory,
      currentCategory,
      isBulkUpdateProductsRelated,
      lunchBulkUpdateProductsRelated,
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
