<template>
  <b-sidebar
    id="bulk-update-products-related"
    :visible="isBulkUpdateProductsRelated"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-bulk-update-products-related', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Mettre à jour tous les prix TTC - {{ category.name }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <b-form-group
            label="Nouveau prix"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="price"
              type="text"
              autofocus
              placeholder="Nouveau prix"
            />
          </b-form-group>
          <h6 class="mb-1">
            Choisir un fournisseur
          </h6>
          <v-select
            v-model="selectedSupplier"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="suppliers"
            label="company_name"
            input-id="invoice-data-client"
            :clearable="false"
            :reduce="supplier => supplier.id"
          />
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Sauvegarder
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'BulkUpdateProductsRelated',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // Form Validation
    ValidationObserver,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isBulkUpdateProductsRelated',
    event: 'update:is-bulk-update-products-related',
  },
  props: {
    isBulkUpdateProductsRelated: {
      type: Boolean,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
    suppliers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  methode: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup(props, { emit }) {
    const category = ref(props.currentCategory)
    const selectedSupplier = ref(null)
    const price = ref(0)

    const onSubmit = async () => {
      try {
        await axiosIns.put('/bulk-update-product-price/', {
          new_price_ttc: price.value,
          category_id: category.value.id,
          supplier_id: selectedSupplier.value,
        })
        emit('update:is-bulk-update-products-related', false)
      } catch (error) {
        console.error(error)
      }
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      category,
      refFormObserver,
      getValidationState,
      onSubmit,
      price,
      selectedSupplier,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}
</style>
